<script setup lang="ts">
// DON'T REMOVE AUTHLESS_OLD LAYOUT
const route = useRoute();

useHead({
  titleTemplate: () => {
    return route.meta.title ? `${route.meta.title} - Rise Pay` : "Rise Pay";
  },
});
</script>

<template>
  <div class="relative min-h-screen flex flex-col">
    <div v-if="route.meta.blank">
      <slot />
    </div>
    <div
      v-else
      class="min-w-screen z-10 bg-black dark:bg-black font-serif flex flex-col flex-grow"
    >
      <div
        class="z-10 relative overflow-hidden px-4 py-6 md:h-full md:w-full md:px-20 md:py-10"
      >
        <div>
          <div
            class="mb-2 bg-gradient-to-r from-[#3CA4E0] to-[#4E62D8] bg-clip-text text-[30px] font-bold text-transparent md:text-[48px]"
          >
            <img
              src="/brand/rise-logo-white.svg"
              class="mx-auto relative block h-[30px] w-auto md:mx-0 md:h-[43px]"
            />
          </div>
          <div
            class="text-sm font-light hidden md:block text-white opacity-70 max-w-[210px]"
          >
            The easiest and faster way to send and receive payments.
          </div>
        </div>
      </div>
      <div class="absolute h-full w-full top-0">
        <video class="object-fill h-full w-full" autoplay loop muted>
          <source src="/videos/menu-bg.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="flex flex-col flex-grow z-10 relative md:py-7 md:px-8">
        <slot />
      </div>
    </div>
  </div>
</template>
